<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud
            ref="crud" :option="tableOption" :data="list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="viewOpen('add')">{{$t('curr.text18')}} </el-button>
            </template>  
            <template #appName='scope'>
                {{getAppName(scope.row.appId)}}
            </template>
            <template #chain_protocol_coin='scope'>
                {{scope.row.chain}}/{{scope.row.protocol}}/{{scope.row.coin}}
            </template> 
            <template #caozuo='scope'>
                <!-- <el-button text type="primary" size="small"   v-if='scope.row.status === 2' 
                    @click="audit(1,$t('withdraw.text1'),scope.row)">{{$t('withdraw.text1')}}</el-button> -->
                <el-button text type="primary" size="small"   v-if='scope.row.status === 0'   
                    @click="audit(3,$t('withdraw.text2'),scope.row)">{{$t('withdraw.text2')}}</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 0'   
                    @click="audit(2,$t('withdraw.text3'),scope.row)">{{$t('withdraw.text3')}}</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 1 && scope.row.withdrawType === 1'   
                    @click="lookView(scope.row)">查看</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 2 && scope.row.withdrawType === 0'   
                    @click="lookView(scope.row)">查看</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 4 && scope.row.withdrawType === 2'   
                    @click="writeFun(scope.row)">录入TXID</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 1 && scope.row.withdrawType === 2'   
                    @click="writeFun(scope.row)">录入TXID</el-button>
            </template>   
            <template #applyTime="scope">
                {{turnTimeFun(scope.row.applyTime)}}
            </template>
            <template #auditTime="scope">
                {{turnTimeFun(scope.row.auditTime)}}
            </template>
        </avue-crud>
        <addView ref='addView_' @successFun='successFun'/>
        <el-dialog v-model="auditParm.innerVisible1" width="600" :title="auditParm.titleText">
            <div style="margin-bottom: 10px">{{strArray[auditParm.style]}}</div>
            <el-radio-group v-model="withdrawType" size="large" style="margin-bottom: 10px;" v-if='auditParm.style === 3'>
                <el-radio-button :label="1" >热提</el-radio-button>
                <el-radio-button :label="2" >冷提</el-radio-button>
            </el-radio-group>
            <el-input type="textarea" :placeholder="$t('inputTit.shuru')" v-model='auditParm.reasons' v-if='auditParm.style > 1'></el-input>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="auditParm.innerVisible1 = false">{{$t('button.close')}}</el-button>
                    <el-button type="primary" @click="enterFun"> {{$t('button.enter')}} </el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="auditParm.innerVisible" width="30%" :title="$t('withdraw.text1')" append-to-body >
            <div>{{strArray[4]}}{{auditParm.code_ }}</div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="auditParm.innerVisible = false;auditParm.innerVisible1 = false">{{$t('button.closeQ')}}</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="auditParm.innerVisible3" width="30%" title="录入TXID" append-to-body >
            <div style="margin-bottom: 10px">请填写TXID</div>
            <el-input type="textarea" :placeholder="$t('inputTit.shuru')" v-model='txids' ></el-input>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="auditParm.innerVisible3 = false">{{$t('button.close')}}</el-button>
                    <el-button type="primary" @click="writeFunEnter"> {{$t('button.enter')}} </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/cryptocurrency/customerWithdrawal";
    import apiList from '@/const/apiList'
    import addView from './add.vue'
    // import copyView from './copy.vue'
    import { ElNotification } from 'element-plus'
    import { userStore } from '@/store/user'
    import { object } from '@/const/from/cryptocurrency/withdrawal/customerWithdrawal'
    import fromSearch from '@/components/fromSearch.vue'
    const object_ = ref(object);
    const { $t } = getCurrentInstance().proxy;
    import { turnTimeFun } from '@/utils/util.js'
    import { randomCoding } from '@/utils/util.js'
    
    const list = ref([])
    const withdrawType = ref(1)
    const txids = ref('')
    // const copyShow_ = ref(null)
    const addView_ = ref(null)
    const listLoading = ref(false)
    const tableOption = ref({});
    const page = reactive({
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
    })
    // const roleManager_btn_edit = ref(true)
    const roleManager_btn_add = ref(true)
    const serchValue = reactive({
        tenantId: '',
        appId: '',
        status: '',
        createTimes: [],
        chainArray:[],
        coin:'',
        walletName: ''
    })
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    serchValue.tenantId = userStore().getUserInfo.tenantId
    const tenantArray = ref([])
    const appArray = ref([])
    const chainList = ref([])
    const crud = ref(null)

    const strArray = ['',$t('withdraw.paragraph1'),$t('withdraw.paragraph2'),$t('withdraw.paragraph3'),$t('withdraw.paragraph4')]
    // const textArray = ['',]
    const auditParm = reactive({
        style: '',
        titleText: '',
        innerVisible1: false,
        innerVisible: false,
        innerVisible3: false,
        obj: {},
        reasons: '',
        code_:''
    })

    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;
    tableOption.value = tableOption1(data)})
    apiList.getChainList((res)=>{chainList.value = res})

    const lookView = (row)=>{
        crud.value.rowView(row)
    }
    const writeFunEnter = ()=>{
        Api_.entryTxId({
            txid: txids.value,
            applyId: auditParm.obj.applyId,
        }).then(res=>{
            if(res.code === 0){
                auditParm.innerVisible3 = false
                getList()
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    const writeFun = (row)=>{
        auditParm.obj = row
        auditParm.innerVisible3 = true
    }
    const getAppName = (e)=>{
        if(e){
            for(let item of appArray.value){
                if(e == item.appId){
                    return item.appName
                } 
            }
        }
    }
    const audit = (e,str,obj_)=>{
        auditParm.style = e
        auditParm.titleText = str
        auditParm.innerVisible1 = true
        auditParm.reasons = ''
        if(obj_){
            auditParm.obj = obj_
        }
    }
    const enterFun = ()=>{
        if(auditParm.style === 1){//重新申请
            let obj = JSON.parse(JSON.stringify(auditParm.obj))
            obj.transactionId = randomCoding(32)
            delete obj.applyId
            Api_.addCustomerWithdrawal(obj).then(res=>{
                if(res.code === 0){
                    auditParm.innerVisible = true
                    auditParm.code_ = res.data.applyId
                    getList(1)
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }else{
            let obj_ = {
                auditReason: auditParm.reasons,
                applyId: auditParm.obj.applyId,
            }
            if(auditParm.style === 2){//拒绝
                if(!auditParm.reasons){
                    ElNotification({
                        message: $t('withdraw.paragraph5'),
                        type: 'error',
                    })
                }else{
                    obj_.status = 2
                    Api_.updateCustomerWithdrawal(obj_).then(res=>{
                        if(res.code === 0){
                            ElNotification({
                                message: $t('alert.succBind'),
                                type: 'success',
                            })
                            getList(1)
                            auditParm.innerVisible1 = false
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }else{
                obj_.status = 1
                obj_.withdrawType = withdrawType.value
                Api_.updateCustomerWithdrawal(obj_).then(res=>{
                    if(res.code === 0){
                        ElNotification({
                            message: $t('alert.succBind'),
                            type: 'success',
                        })
                        getList(1)
                        auditParm.innerVisible1 = false
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        }
    }
    const successFun = () => {
        page.currentPage = 1;
        getList(1)
    }
    const viewOpen = (str,scope)=>{
        addView_.value.setData(tenantArray,appArray,scope,str)
    }
    const getList = (e)=>{
        let obj_ = {
            current: page.currentPage,
            size: page.pageSize,
            // applyUserType: 2
        }
        if(e && e !== 1){
            obj_ = Object.assign({}, obj_, e)
        }
        listLoading.value = true
        Api_.pageByMer(obj_).then(res=>{
            if(res.code === 0){
                listLoading.value = false
                list.value = res.data.records
                page.total = res.data.total
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    // const delFun = (roleId)=>{
    //     ElMessageBox.confirm($t('alert.selEnter'), $t('tyle_.tits'), {
    //         confirmButtonText: $t('button.enter'),
    //         cancelButtonText: $t('button.close_'),
    //         type: 'warning',
    //     }).then(()=>{
    //         Api_.delAssetswalletinfoStatus(roleId).then(res=>{
    //             if(res.code ===0 ){
    //                 getList(1)
    //                 ElNotification({
    //                     message: $t('alert.succDel'),
    //                     type: 'success',
    //                 })
    //             }else{
    //                 ElNotification({
    //                     message: res.msg,
    //                     type: 'error',
    //                 })
    //             }
    //         })
    //     })
    // }
    const sizeChange = (pageSize)=>{ 
        page.pageSize = pageSize;
        getList(1)
    }
    const currentChange = (currentPage)=>{ 
        page.currentPage = currentPage;
        getList(1)
    }
    getList(1)
</script>

