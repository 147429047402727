<template>
    <div class="init">
        <el-dialog v-model="dialogBool" lock-scroll
            :title="style_ === 'add'?$t('curr.text18'):$t('button.update')" width="600" >
            <el-form :model="fromValue" label-position="top" ref='form_' class="fromClass"
                :rules='rules'>
                    <el-form-item :label="$t('currencySet.name1')"  v-if='isPlatform && style_ === "add"'>
                        <el-select v-model="fromValue_.tenantId" :placeholder="$t('inputTit.xuanze')" :disabled='style_ === "update"' :teleported='false'>
                            <el-option :label="item.name" :value="item.id" v-for='item in tenantArray' :key = 'item.id'/>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name2')" v-if='style_ === "add"'>
                        <el-select v-model="fromValue_.appId" :placeholder="$t('inputTit.xuanze')" :disabled='style_ === "update"' :teleported='false'>
                            <el-option :label="item.appName" :value="item.appId" v-for='item in appArray' :key = 'item.appId'  />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('otherWallat.text3')">
                        <el-radio-group v-model="fromValue_.walletType" size="large" :disabled='style_ === "update"' @change="changeFun">
                            <el-radio-button :label="1">{{$t('withdraw.text4')}}</el-radio-button>
                            <el-radio-button :label="2">{{$t('withdraw.text5')}}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item :label="$t('withdraw.text6')" prop="chain_protocol_coin">
                        <el-input v-model="fromValue.chain_protocol_coin" disabled :placeholder="$t('inputTit.xuanze')">
                            <template #append v-if='fromValue_.tenantId && fromValue_.appId'>
                                <el-button text type="primary" @click="chooseCoin">{{$t('button.checks')}}</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('withdraw.name1')" prop="toAddress">
                        <el-input v-model="fromValue.toAddress" :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
                    <el-form-item :label="$t('withdraw.name2')" prop="applyReason">
                        <el-input v-model="fromValue.applyReason" :placeholder="$t('inputTit.shuru')" type='textarea'/>
                    </el-form-item>
                    <el-form-item :label="$t('withdraw.name3')" prop="amount">
                        <el-input v-model="fromValue.amount" :placeholder="$t('inputTit.xuanze')" :disabled='!fromValue.chain_protocol_coin'>
                            <template #suffix>
                                <div>
                                    可用余额：{{amount_}}
                                </div>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$t('currencySet.name29')" prop="googleCode">
                        <el-input v-model="fromValue.googleCode"  :placeholder="$t('inputTit.shuru')"/>
                    </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogBool = false">{{$t('button.closeQ')}}</el-button>
                    <el-button type="primary" @click="completeFun(form_)">{{$t('button.tijiao')}}</el-button>
                </span>
            </template>
        </el-dialog>
         <el-dialog v-model="innerVisible1" width="900" :title="$t('withdraw.text6')" append-to-body>
            <addrView @getCoin1='getCoin1' ref='addrView_'/>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,nextTick,defineEmits,getCurrentInstance } from 'vue'
    import { userStore } from '@/store/user'
    import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { randomCoding } from '@/utils/util.js'
    
    const fromValue = reactive({ 
        applyId: '',amount: '',applyUser:'',
        applyUserType:2,
        chain:'',coin:'',fromAddress:'',fromWalletId:'',protocol:'',
        toAddress: '',chain_protocol_coin:'',
        transactionId: '',
        protocolType: 1,applyReason:'',
    })
    const fromValue_ = reactive({
        userId:'',appId:'',
        walletType: 1,tenantId:''
    })
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    console.log(userStore().getUserInfo,888)
    fromValue.applyUser = userStore().getUserInfo[0].username;
    fromValue.tenantId = userStore().getUserInfo[0].tenantId;
    fromValue_.tenantId = userStore().getUserInfo[0].tenantId;
    fromValue.userId = userStore().getUserInfo[0].userId;
    // fromValue
    const emit = defineEmits(['successFun'])
    const tenantArray = ref([])
    const appArray = ref([]); 
    const innerVisible1 = ref(false)
    const form_ = ref(null)
    const rules = ref(null);
    rules.value = addRules.mainRules
    const dialogBool = ref(false)
    const style_ = ref('add')
    const addrView_ = ref(null)
    const amount_ = ref(null)
    
    const chooseCoin = ()=>{
        innerVisible1.value = true
        nextTick(()=>{
            fromValue_.userId = fromValue_.tenantId
            addrView_.value.init_(fromValue_)
        })
    }
    const changeFun = ()=>{
        fromValue.chain_protocol_coin = '';
        fromValue.chain = '';
        fromValue.protocol = '';
        fromValue.coin = '';
        amount_.value = ''
    }
    const completeFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                delete obj_.chain_protocol_coin
                obj_.appId = fromValue_.appId
                obj_.tenantId = fromValue_.tenantId
                obj_.transactionId = randomCoding(64)
                if(style_.value === 'add'){
                    Api_.addCustomerWithdrawal(obj_,fromValue.googleCode).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            dialogBool.value = false
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.upAssetswalletinfoStatus(obj_,fromValue.googleCode).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            dialogBool.value = false
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const setData = (e1,e2,scope,style)=>{//获得列表数据
        appArray.value  = e2.value 
        tenantArray.value  = e1.value 
        if(isPlatform.value){
            tenantArray.value  = e1.value 
        }else{
            fromValue_.userId = userStore().getUserInfo.tenantId
        }
        dialogBool.value = true;style_.value = style
        nextTick(()=>{
            if(style_.value === 'add'){
                form_.value.resetFields()
                fromValue_.userId = ''
                fromValue_.appId = ''
                fromValue_.walletType = 1
            }else{//修改遍历对象
                fromValue.chain_protocol_coin = scope.chain + '-' + scope.walletName + '-' + scope.coin;
                for(let i in scope){
                    fromValue[i] = scope[i]
                }
            } 
        })
        
    }
    const getCoin1 = (obj)=>{
        fromValue.chain = obj.chain;
        fromValue.coin = obj.coin;
        fromValue.protocol = obj.protocol;
        fromValue.fromAddress = obj.walletAddress;
        fromValue.fromWalletId = obj.walletId;
        innerVisible1.value = false;
        fromValue.chain_protocol_coin = obj.chain + '-' + obj.walletName + '-' + obj.coin;
        amount_.value = obj.amount
    }
    defineExpose({setData})
</script>
<style lang="scss" scoped>
    
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
</style>